
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { useStore } from 'vuex'
import Form from './Form.vue'

export default defineComponent({
  components: {
    Form,
  },
  setup () {
    const form = reactive({
      id: '',
      sold_to: '',
      retailer_name: '',
    })
    const form_action = ref('save')
    const visible = ref(false)
    const store = useStore()
    const fields = ref([
      {
        title: 'Sold To',
        dataIndex: 'sold_to',
      },
      {
        title: 'Retailer Name',
        dataIndex: 'retailer_name',
      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('Retailer/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, sold_to, retailer_name} = toRefs(form)
      id.value = record.id
      sold_to.value = record.sold_to
      retailer_name.value = record.retailer_name
      visible.value = true
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {id, sold_to, retailer_name} = toRefs(form)
      id.value = ''
      sold_to.value = ''
      retailer_name.value = ''
      visible.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('Retailer/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record)
    }

    const storeRecord = (payload = {}) => {
      store
          .dispatch('Retailer/store', payload)
          .then(() => (visible.value = false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('Retailer/update', payload)
          .then(() => (visible.value = false))
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['Retailer/items'])
    const loading = computed(() => store.getters['Retailer/status'])

    onMounted(index)

    return {
      loading,
      items,
      fields,
      visible,
      form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    }
  },
})
